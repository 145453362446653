
/*
Tables

Panel table title
*/
.panel-title{
	font-size: 1.5em;	
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active     >     a:focus {
    background-color: #00A65A !important;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
    background-color: #00A65A;
    border-color: #00A65A;
}
/* Table header */
.thead-gray{
	background-color: rgba(179, 179, 179, 0.38);
}
.thead-gray tr th:hover {
    background-color: rgba(179, 179, 179, 0.1);
}



/* Profile */
span.hoverimgCamera {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  opacity:0;
  transition:opacity 0.3s linear;
  background-color: rgba(137,137,137,0.5);
  border-radius: 50px;
}
li.hoverimgCamera:hover span.hoverimgCamera{
	opacity: 1;
}


li.hoverimgCamera{
	display: inline-block;
	height: 100px;
	position: relative;
	width: 100px;
}









/* Outline buttons */
.btn-primary-outline {
  border: 1px solid #2e6da4;
  background: transparent;
  color: #2e6da4 !important;
}
.btn-primary-outline:hover{
  background: #337ab7;
  color: white !important;
}

.btn-success-outline{
  border: 1px solid #00A65A;
  background: transparent;
  color: #00A65A !important;
}
.btn-success-outline:hover{
  background: #00A65A;
  color: white !important;
}

.btn-danger-outline{
  border: 1px solid #d43f3a;
  background: transparent;
  color: #d43f3a !important;
}
.btn-danger-outline:hover{
  background: #d9534f;
  color: white !important;
}

.btn-info-outline{
  border: 1px solid #46b8da;
  background: transparent;
  color: #46b8da !important;
}
.btn-info-outline:hover{
  background: #5bc0de;
  color: white !important;
}

.btn-warning-outline{
  border: 1px solid #eea236;
  background: transparent;
  color: #eea236 !important;
}
.btn-warning-outline:hover{
  background: #f0ad4e;
  color: white !important;
}