span.hoverimgCamera {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  opacity:0;
  transition:opacity 0.3s linear;
  background-color: rgba(137,137,137,0.5);
  border-radius: 50px;
}
li.hoverimgCamera:hover span.hoverimgCamera{
	opacity: 1;
}


li.hoverimgCamera{
	display: inline-block;
	height: 100px;
	position: relative;
	width: 100px;
}
