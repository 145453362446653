.img-circle-zoom:hover {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    position: absolute;
    z-index: 10;
    cursor: none;
}
.img-circle-zoom:not( :hover ) {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    position: absolute;
    z-index: 1;
    cursor: none;
}